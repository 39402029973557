import React, { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { getAppRouter, publicRoutes } from "./router";
import { AppContext } from "./context/AppContext";
import "./App.scss";
import { PageLoader } from "./components/Loader";

function App() {
    const { isAuthenticated, isAuthenticating, login, user, getUser } =
        useContext(AppContext);

    const isPublicRoute = (path: string) => {
        return publicRoutes.includes(path);
    };

    const isPublic = isPublicRoute(window.location.pathname);

    useEffect(() => {
        if (!isPublic && !isAuthenticated && !isAuthenticating) login();
        else if (isAuthenticated && !isAuthenticating) getUser();
    }, [isAuthenticating, isAuthenticated, login]);

    return user || (!user && isPublic) ? (
        <RouterProvider router={getAppRouter(user, isPublic)} />
    ) : (
        <PageLoader />
    );
}

export default App;
