import { ReferenceField, UserPermissionSet } from "../interfaces";

export const Roles = {
    systemAdmin: "System Admin",
    admin: "Admin",
    standard: "Standard",
};

export const SelectableRoles: ReferenceField[] = Object.values(Roles).map(
    (val) => {
        return {
            value: val,
            label: val,
        };
    },
);

export const userHasRole = (
    user: UserPermissionSet | null | undefined,
    roles: string[],
) => {
    if (!user) return false;
    return roles.includes(user.permissions.role);
};
