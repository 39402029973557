import { AxiosError, AxiosInstance } from "axios";
import { handleError } from "../helpers/error";
import { WotcScreening } from "../interfaces";

/**
 * Retrieves all WOTC screenings associated with the given candidate ID.
 *
 * @param getApi - A function that returns an authenticated Axios instance.
 * @param candidateId - The ID of the candidate to retrieve screenings for.
 * @returns An array of WOTC screening objects.
 */
export const getWotcScreenings = async (
    getApi: () => Promise<AxiosInstance>,
    candidateId: string,
) => {
    try {
        const api = await getApi();
        const response = await api.get(
            `tax-credits/candidate/${candidateId}/screenings`,
        );
        return response.data as WotcScreening[];
    } catch (e) {
        handleError(e as AxiosError);
    }
};

/**
 * Initiates a new WOTC screening for the given candidate ID.
 *
 * @param getApi - A function that returns an authenticated Axios instance.
 * @param candidateId - The ID of the candidate to initiate the screening for.
 * @returns The newly created WOTC screening object.
 */
export const createWotcScreening = async (
    getApi: () => Promise<AxiosInstance>,
    candidateId: string,
) => {
    try {
        const api = await getApi();
        const response = await api.post(
            `tax-credits/candidate/${candidateId}/screening/initiate`,
        );
        return response.data as WotcScreening;
    } catch (e) {
        handleError(e as AxiosError);
    }
};

/**
 * Resends the WOTC screening email associated with the given screening ID.
 *
 * @param getApi - A function that returns an authenticated Axios instance.
 * @param screeningId - The ID of the WOTC screening to resend the email for.
 * @returns The WOTC screening object with the email resent status.
 */
export const resendWotcScreeningEmail = async (
    getApi: () => Promise<AxiosInstance>,
    screeningId: string,
): Promise<WotcScreening | undefined> => {
    try {
        const api = await getApi();
        const response = await api.post(
            `tax-credits/screening/${screeningId}/resend-email`,
        );
        return response.data as WotcScreening;
    } catch (e) {
        handleError(e as AxiosError);
    }
};

/**
 * Synchronizes the WOTC screening data for the given screening ID.
 *
 * @param getApi - A function that returns an authenticated Axios instance.
 * @param screeningId - The ID of the WOTC screening to synchronize.
 * @returns The synchronized WOTC screening object.
 */
export const synchronizeWotcScreening = async (
    getApi: () => Promise<AxiosInstance>,
    screeningId: string,
): Promise<WotcScreening | undefined> => {
    try {
        const api = await getApi();
        // Make a POST request to synchronize the WOTC screening data
        const response = await api.post(
            `tax-credits/screening/${screeningId}/synchronize`,
        );
        // Return the synchronized WOTC screening data
        return response.data as WotcScreening;
    } catch (e) {
        // Handle any errors that occur during the API request
        handleError(e as AxiosError);
    }
};
