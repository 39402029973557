import { AxiosError } from "axios";
import { ApiError } from "../interfaces";

/**
 * Show an error alert to the user.
 * @param {string} message - The error message to display to the user.
 */
export const showErrorAlert = (message: string) => {
    alert(message);
};

/**
 * Handle an Axios error by logging it and displaying an error alert.
 * If the error is a 401 unauthorized error, redirect the user to the home page.
 * @param {AxiosError} e - The Axios error object.
 */
export const handleError = (e: AxiosError) => {
    // log the error
    console.error(e);

    // check for 401 auth error
    if (e?.response?.status === 401) {
        // redirect user to home page
        window.location.href = "/";
    }

    // display error message to user
    const message =
        (e?.response?.data as ApiError)?.message ||
        "There was an error processing this request";
    showErrorAlert(message);
};
