import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import DataTable, { TableColumn } from "react-data-table-component";
import { CircularProgress, Container } from "@mui/material";
import Button from "@mui/material/Button";
import { WotcScreeningsListProps } from "../interfaces/props";
import { DatetimeFormat, WotcScreening } from "../interfaces";
import { AppContext } from "../context/AppContext";
import {
    createWotcScreening,
    getWotcScreenings,
    resendWotcScreeningEmail,
    synchronizeWotcScreening,
} from "../api/screenings";
import { showErrorAlert } from "../helpers/error";

export const WotcScreeningsList = (props: WotcScreeningsListProps) => {
    const { candidateId } = props;
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [resending, setResending] = useState<string | null>(null);
    const [syncing, setSyncing] = useState<string | null>(null);
    const [screenings, setScreenings] = useState<WotcScreening[] | null>(null);

    const fetchScreenings = async (
        candidateId: string,
        doLoading?: boolean,
    ) => {
        if (doLoading) setLoading(true);
        const screeningsResponse = await getWotcScreenings(getApi, candidateId);
        if (screeningsResponse) setScreenings(screeningsResponse);
        if (doLoading) setLoading(false);
    };

    const showEmailError = (screening: WotcScreening): void => {
        if (
            screening?.emailSuccess === false &&
            typeof screening?.screeningId === "string"
        ) {
            showErrorAlert(
                "The WOTC screening was initiated but the email to the customer was not sent successfully. Click the link below to resend the email.",
            );
        }
    };

    const resendEmail = async (screeningId: string) => {
        setResending(screeningId);
        const resendResponse = await resendWotcScreeningEmail(
            getApi,
            screeningId,
        );
        setResending(null);

        // show error if email wasnt sent
        if (resendResponse) showEmailError(resendResponse);
    };

    const synchronizeScreening = async (screeningId: string) => {
        setSyncing(screeningId);
        const response = await synchronizeWotcScreening(getApi, screeningId);
        if (response && candidateId) await fetchScreenings(candidateId, false);
        setSyncing(null);
    };

    const initiateScreening = async (candidateId: string | null) => {
        if (!candidateId) return;
        setLoading(true);
        const newScreening = await createWotcScreening(getApi, candidateId);
        if (newScreening?.id) {
            await fetchScreenings(candidateId);
        }
        setLoading(false);

        // show error if email wasnt sent
        if (newScreening) showEmailError(newScreening);
    };

    useEffect(() => {
        if (candidateId) fetchScreenings(candidateId);
    }, [candidateId]);

    const columns: TableColumn<WotcScreening>[] = [
        {
            name: "Status",
            selector: (row: WotcScreening) => row.status ?? "",
            width: "160px",
        },
        {
            name: "Opt-out",
            selector: (row: WotcScreening) => (row.optOut ? "Yes" : "No"),
            width: "80px",
        },
        {
            name: "Eligibility",
            selector: (row: WotcScreening) =>
                row.eligibility
                    ? row.eligibility.charAt(0).toUpperCase() +
                      row.eligibility.slice(1)
                    : "n/a",
            width: "100px",
        },
        {
            name: "Potential credit amount",
            selector: (row: WotcScreening) =>
                row.potentialCreditAmount != null
                    ? `$${row.potentialCreditAmount.toFixed(2)}`
                    : "n/a",
            width: "160px",
        },
        {
            name: "Created",
            selector: (row: WotcScreening) =>
                dayjs(row.created).format(DatetimeFormat),
            width: "180px",
        },
        {
            name: "Updated",
            selector: (row: WotcScreening) =>
                dayjs(row.updated).format(DatetimeFormat),
            width: "180px",
        },
        {
            name: "Screening ID",
            selector: (row: WotcScreening) => row.screeningId ?? "",
        },
        {
            name: "Resend Email",
            selector: (row: WotcScreening) => row.id,
            cell: (row: WotcScreening) =>
                row.status !== "Screening Completed" && row.optOut !== true ? (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => resendEmail(row.id)}
                        disabled={resending === row.id || syncing === row.id}
                        style={{
                            textTransform: "none",
                            fontSize: "12px",
                            padding: "0px 8px",
                        }}
                    >
                        {resending === row.id ? "Resending..." : "Resend Email"}
                    </Button>
                ) : (
                    ""
                ),
            width: "140px",
        },
        {
            name: "Sync",
            selector: (row: WotcScreening) => row.id,
            cell: (row: WotcScreening) =>
                row.status !== "Screening Completed" && row.optOut !== true ? (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => synchronizeScreening(row.id)}
                        disabled={resending === row.id || syncing === row.id}
                        style={{
                            textTransform: "none",
                            fontSize: "12px",
                            padding: "0px 8px",
                        }}
                    >
                        {syncing === row.id ? "Syncing..." : "Sync"}
                    </Button>
                ) : (
                    ""
                ),
            width: "140px",
        },
    ];
    return (
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <h1>WOTC Screenings</h1>
            <p>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => initiateScreening(candidateId)}
                    disabled={loading}
                >
                    Initiate WOTC Screening
                </Button>
                {candidateId && (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => fetchScreenings(candidateId)}
                        disabled={loading}
                        style={{ marginLeft: "10px" }}
                    >
                        Refresh
                    </Button>
                )}
            </p>
            {loading && <CircularProgress />}
            {!loading && screenings && (
                <DataTable
                    data={screenings}
                    columns={columns}
                    pagination
                    dense={true}
                />
            )}
        </Container>
    );
};
