import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import { Roles, userHasRole } from "./helpers/auth";
import { UserPermissionSet } from "./interfaces";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { PageWrapper } from "./components/PageWrapper";
import { DashboardPage } from "./pages/DashboardPage";
import { OrganizationsPage } from "./pages/OrganizationsPage";
import { OrganizationPage } from "./pages/OrganizationPage";
import { UsersPage } from "./pages/UsersPage";
import { UserPage } from "./pages/UserPage";
import { UserAddPage } from "./pages/UserAddPage";
import { CompanyAddPage } from "./pages/CompanyAddPage";
import { CompaniesPage } from "./pages/CompaniesPage";
import { CompanyPage } from "./pages/CompanyPage";
import { CandidatesPage } from "./pages/CandidatesPage";
import { CandidatePage } from "./pages/CandidatePage";
import { CandidateAddPage } from "./pages/CandidateAddpage";
import { SiteLocationAddPage } from "./pages/SiteLocationAddPage";
import { SiteLocationsPage } from "./pages/SiteLocationsPage";
import { SiteLocationPage } from "./pages/SiteLocationPage";
import { ContactAddPage } from "./pages/ContactAddPage";
import { ContactsPage } from "./pages/ContactsPage";
import { ContactPage } from "./pages/ContactPage";
import { JobAddPage } from "./pages/JobAddPage";
import { JobsPage } from "./pages/JobsPage";
import { JobPage } from "./pages/JobPage";
import { CompanyProfile } from "./pages/CompanyProfile";
import { CandidateProfile } from "./pages/CandidateProfile";
import { PlacementPage } from "./pages/PlacementPage";
import { PlacementsPage } from "./pages/PlacementsPage";
import { BackofficePage } from "./pages/BackofficePage";
import { WorkCompCodePage } from "./pages/WorkCompCodePage";
import { CommissionTypePage } from "./pages/CommissionTypePage";
import { TimeTrackingListPage } from "./pages/TimeTrackingListPage";
import { TimeTrackingPeriodPage } from "./pages/TimeTrackingPeriodPage";
import { UserPlacementCommissionsPage } from "./pages/UserPlacementCommissionsPage";
import { PipelinePage } from "./pages/PipelinePage";
import { NominationPage } from "./pages/NominationPage";
import { WotcScreeningCompleted } from "./pages/WotcScreeningCompleted";

export const publicRoutes = ["/wotc-screening-completed"];

export const getAppRouter = (
    user: UserPermissionSet | null | undefined,
    isPublic: boolean,
) => {
    return createBrowserRouter([
        {
            path: "/",
            element: <PageWrapper isPublic={isPublic} />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "",
                    element: <DashboardPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "organizations/:organizationId",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [Roles.systemAdmin])}
                        >
                            <OrganizationPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "organizations",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [Roles.systemAdmin])}
                        >
                            <OrganizationsPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "users/:userId",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.admin,
                                Roles.systemAdmin,
                            ])}
                        >
                            <UserPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "users/add",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <UserAddPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "users",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <UsersPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/profile",
                    element: <CompanyProfile />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId",
                    element: <CompanyPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies",
                    element: <CompaniesPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/add",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <CompanyAddPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "candidates/:candidateId/profile",
                    element: <CandidateProfile />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "candidates",
                    element: <CandidatesPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "candidates/add",
                    element: <CandidateAddPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "candidate/:candidateId",
                    element: <CandidatePage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/site-locations",
                    element: <SiteLocationsPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/site-locations/:siteLocationId",
                    element: <SiteLocationPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/companies/:companyId/site-locations/add",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <SiteLocationAddPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/contacts/:contactId",
                    element: <ContactPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/companies/:companyId/contacts/add",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <ContactAddPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/contacts",
                    element: <ContactsPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/companies/:companyId/jobs/add",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <JobAddPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/jobs",
                    element: <JobsPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "companies/:companyId/job/:jobId",
                    element: <JobPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "placements",
                    element: <PlacementsPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "placements/:placementId",
                    element: <PlacementPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "pipeline/:nominationId",
                    element: <NominationPage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "pipeline",
                    element: <PipelinePage />,
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/backoffice",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <BackofficePage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/backoffice/worker-comp-codes/:compCodeId",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <WorkCompCodePage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/backoffice/commission-types/:commissionTypeId",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <CommissionTypePage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/backoffice/time-tracking",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <TimeTrackingListPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/backoffice/time-tracking/:timeTrackingPeriodId/user-commission/:userId",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <UserPlacementCommissionsPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "/backoffice/time-tracking/:timeTrackingPeriodId/*",
                    element: (
                        <ProtectedRoute
                            isAllowed={userHasRole(user, [
                                Roles.systemAdmin,
                                Roles.admin,
                            ])}
                        >
                            <TimeTrackingPeriodPage />
                        </ProtectedRoute>
                    ),
                    errorElement: <ErrorPage />,
                },
                {
                    path: "wotc-screening-completed",
                    element: <WotcScreeningCompleted />,
                    errorElement: <ErrorPage />,
                },
            ],
        },
    ]);
};
