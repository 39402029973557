import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";

export const WotcScreeningCompleted = () => {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>WOTC Screening Completed</h1>
                    <p>
                        Thank you for completing your WOTC screening. You may
                        close the page now.
                    </p>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
